import React, { useContext, useEffect, useState } from "react";
import { UserManagementContext } from "../../context/UserManagementProvider";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ExcludedMenuTitles from "../ExcludedMenuTitles/ExcludedMenuTitles";

import "./EditUser.css";

const UserForm = (menuItem) => {
  // Context Variables
  const navigate = useNavigate();
  const { selectedUser, updateUser, deleteLocalUser, cancelUser, usernameAvailable, checkUsername } = useContext(UserManagementContext);
  const { menu } = useContext(SyncReportsContext);

  // State Variables
  const [user, setUser] = useState(selectedUser || { new: true, reports: {} });
  const [, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const EXCLUDED_MENU_TITLES = ExcludedMenuTitles(); // Get excluded titles

  useEffect(() => {
    if (selectedUser) {
      const formattedReports = selectedUser.reports.reduce((acc, curr) => {
        acc[curr.report_id] = curr.access;
        return acc;
      }, {});

      setUser({
        ...selectedUser,
        reports: formattedReports,
      });
    }
  }, [selectedUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));

    if (name === "first_name") {
      setIsFirstNameValid(!!value);
    } else if (name === "last_name") {
      setIsLastNameValid(!!value);
    } else if (name === "email") {
      setIsEmailValid(!!value);
    } else if (name === "username") {
      setIsUsernameValid(!!value);
    }
  };

  const handleSaveOrUpdateUser = async (event) => {
    event.preventDefault();

    if (!usernameAvailable) {
      console.error("Username is not available");
      return;
    }

    const reports = menu.flatMap((category) =>
      category.reports.map((report) => ({
        report_id: report.report_id,
        access: user.reports[report.report_id] || false,
      }))
    );

    const userToSave = {
      ...user,
      reports,
      email: user.username,
    };

    await updateUser(userToSave);
    navigate("/usermanagement");
  };

  const handleDeleteUser = async () => {
    await deleteLocalUser(user.id);
    navigate("/usermanagement");
  };

  const confirmDelete = () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      handleDeleteUser();
    }
  };

  const handleReportSelection = (
    reportId,
    isChildReport = false,
    parentReportId = null
  ) => {
    setUser((prevUser) => {
      const updatedReports = { ...prevUser.reports };

      if (isChildReport) {
        if (updatedReports[parentReportId]) {
          updatedReports[reportId] = !updatedReports[reportId];
        }
      } else {
        updatedReports[reportId] = !updatedReports[reportId];
        if (!updatedReports[reportId] && parentReportId) {
          updatedReports[parentReportId] = false;
        }
        const parentReport = menu
          .flatMap((category) => category.reports)
          .find((report) => report.report_id === reportId);
        if (parentReport.child_report) {
          updatedReports[parentReport.child_report.report_id] =
            updatedReports[reportId];
        }
      }

      return { ...prevUser, reports: updatedReports };
    });
  };

  const stripHtml = (html) => {
    if (!html) return "";
    return html.replace(/<[^>]*>/g, "");
  };

  const renderTooltip = (report) => (
    <Tooltip id={`tooltip-${report.report_id}`}>
      {stripHtml(getInfo(report))}
    </Tooltip>
  );

  const countSelectedReports = (menuId) =>
    menu
      .find((m) => m.menu_id === menuId)
      .reports.reduce(
        (count, report) => (user.reports[report.report_id] ? count + 1 : count),
        0
      );

  const getInfo = (report) => {
    return report.more_info;
  };

  const sortReports = (reports) => {
    if (!reports || reports.length === 0) return reports;

    return reports.sort((a, b) => {
      const titleA = a.menu_title || a.title;
      const titleB = b.menu_title || b.title;
      return titleA.localeCompare(titleB);
    });
  };

  const renderDashboardMenuCards = (menuItem) => {
    menuItem.reports = sortReports(menuItem.reports);
    const toggleSelectAllReports = () => {
      const areAllSelected = menuItem.reports.every(
        (report) => user.reports[report.report_id]
      );
      const newSelectionStatus = !areAllSelected;

      const updatedReports = menuItem.reports.reduce((acc, report) => {
        acc[report.report_id] = newSelectionStatus;
        return acc;
      }, {});

      setUser((prevUser) => ({
        ...prevUser,
        reports: { ...prevUser.reports, ...updatedReports },
      }));
    };

    return (
      <div key={menuItem.menu_id} className="card">
        <div className="card-title-header" onClick={toggleSelectAllReports}>
          <h3>{menuItem.title}</h3>
        </div>
        <div className="card-header">
          <span className="selected-count">
            <strong>
              {countSelectedReports(menuItem.menu_id)} of{" "}
              {menuItem.reports.length} selected
            </strong>
          </span>
          <div className="card-header-actions">
            <div className="action-item">
              <h5 className="action-title">Download</h5>
              <input
                type="checkbox"
                className="form-check-input"
                id={`download-all-${menuItem.menu_id}`}
                checked={menuItem.reports.every(
                  (report) => user.reports[report.report_id]
                )}
                onChange={(e) => {
                  e.stopPropagation();
                  toggleSelectAllReports();
                }}
              />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-download-all-${menuItem.menu_id}`}>
                    Uncheck this box to remove download access. Learn more about
                    each detail report by hovering over the checkbox in each
                    row.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faDownload} />
              </OverlayTrigger>
            </div>
            <div className="action-item">
              <h5 className="action-title">More Info</h5>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-more-info-${menuItem.menu_id}`}>
                    Learn more about each report by hovering over the info icon
                    in each row.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="list-group-item">
            {menuItem.reports.map((report) => {
              if (report.parent_report_id) return null;

              const reportClass = report.publish !== 1 ? "unpublished" : "";

              return (
                <div
                  key={report.report_id}
                  className={`list-group-itemDash ${reportClass} ${
                    user.reports[report.report_id] ? "selected-report" : ""
                  }`}
                  onClick={() => handleReportSelection(report.report_id)}
                >
                  <div className="report-title-info">
                    {report.menu_title || report.title}
                  </div>
                  {report.child_report && (
                    <OverlayTrigger
                      key={`child-${report.child_report.report_id}`}
                      placement="top"
                      overlay={renderTooltip(report.child_report)}
                    >
                      <div className="report-checkbox">
                        <input
                          type="checkbox"
                          checked={user.reports[report.child_report.report_id]}
                          disabled={!user.reports[report.report_id]}
                          onChange={() =>
                            handleReportSelection(
                              report.child_report.report_id,
                              true,
                              report.report_id
                            )
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </OverlayTrigger>
                  )}

                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(report)}
                  >
                    <span className="float-right info-icon">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                  </OverlayTrigger>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderNonDashboardMenuCards = (menuItem) => {
    menuItem.reports = sortReports(menuItem.reports);
    const toggleSelectAllReports = () => {
      const areAllSelected = menuItem.reports.every(
        (report) => user.reports[report.report_id]
      );
      const newSelectionStatus = !areAllSelected;

      const updatedReports = menuItem.reports.reduce((acc, report) => {
        acc[report.report_id] = newSelectionStatus;
        return acc;
      }, {});

      setUser((prevUser) => ({
        ...prevUser,
        reports: { ...prevUser.reports, ...updatedReports },
      }));
    };

    return (
      <div key={menuItem.menu_id} className="card">
        <div className="card-title-header" onClick={toggleSelectAllReports}>
          <h3>{menuItem.title}</h3>
        </div>
        <div className="card-header">
          <span className="selected-count">
            <strong>
              {countSelectedReports(menuItem.menu_id)} of{" "}
              {menuItem.reports.length} selected
            </strong>
          </span>
          <div className="card-header-actions">
            <div className="action-item">
              <h5 className="action-title">More Info</h5>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-more-info-${menuItem.menu_id}`}>
                    Learn more about each report by hovering over the info icon
                    in each row.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="list-group-item">
            {menuItem.reports.map((report) => {
              if (report.parent_report_id) return null;

              const reportClass = report.publish !== 1 ? "unpublished" : "";

              return (
                <div
                  key={report.report_id}
                  className={`list-group-itemDash ${reportClass} ${
                    user.reports[report.report_id] ? "selected-report" : ""
                  }`}
                  onClick={() => handleReportSelection(report.report_id)}
                >
                  <div className="report-title-info">
                    {report.menu_title || report.title}
                  </div>
                  {report.child_report && (
                    <OverlayTrigger
                      key={`child-${report.child_report.report_id}`}
                      placement="top"
                      overlay={renderTooltip(report.child_report)}
                    >
                      <div className="report-checkbox">
                        <input
                          type="checkbox"
                          checked={user.reports[report.child_report.report_id]}
                          onChange={() =>
                            handleReportSelection(report.child_report.report_id)
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(report)}
                  >
                    <span className="float-right info-icon">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                  </OverlayTrigger>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="table-responsive">
      <form
        name="userForm"
        onSubmit={handleSaveOrUpdateUser}
        autoComplete="off"
      >
        <div className="card">
          <div className="card-header">
            <h5>
              {!user.last_name && !user.first_name
                ? "New User"
                : `${user.last_name}, ${user.first_name}`}
            </h5>
          </div>
          <div className="card-body">
            <div className="jumbotron">
              <div>
                <div className="card">
                  <div className="card-header">
                    <h5>Demographics</h5>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="userFirstName">First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        required
                        className="form-control"
                        id="userFirstName"
                        value={user.first_name || ""}
                        onChange={handleInputChange}
                        placeholder="First Name"
                        autoComplete="off"
                      />
                      {!isFirstNameValid && (
                        <div className="alert alert-danger">
                          First Name is required.
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="userLastName">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        required
                        className="form-control"
                        id="userLastName"
                        value={user.last_name || ""}
                        onChange={handleInputChange}
                        placeholder="Last Name"
                        autoComplete="off"
                      />
                      {!isLastNameValid && (
                        <div className="alert alert-danger">
                          Last Name is required.
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="userUsername">Email</label>
                      <input
                        type="text"
                        name="username"
                        required
                        className="form-control"
                        id="userUsername"
                        minLength="6"
                        value={user.username || ""}
                        onChange={handleInputChange}
                        onKeyUp={() => checkUsername(user.username)}
                        placeholder="Please enter a valid email address"
                        autoComplete="off"
                      />
                      {!isUsernameValid && (
                        <div className="alert alert-danger">
                          Username must be unique and have a minimum length of 6
                          characters.
                        </div>
                      )}
                      {user.username && !usernameAvailable && (
                        <div className="alert alert-danger">
                          Username taken, please select a different username.
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="userPassword">
                        {user.new
                          ? "Set Password"
                          : "Change Password (Optional)"}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="userPassword"
                        name="newpassword"
                        placeholder={
                          user.new
                            ? "Set a temporary password for initial login"
                            : "Leave blank to keep current password"
                        }
                        value={user.newpassword || ""}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="alert alert-info" role="alert">
                    <strong>Unpublished Reports: </strong>Highlighted menu items
                    are reports that are currently unpublished. You can assign
                    access to these reports, but please note they are not yet
                    finalized for production use. When they become available for
                    production use, they will be automatically published and
                    made available to users with access.
                  </div>
                </div>

                <div className="jumbotronCards" style={{ padding: "0px" }}>
                  {menu
                    .filter((menuItem) => menuItem.menu_type === "dashboard")
                    .map((menuItem) => renderDashboardMenuCards(menuItem))}
                  {menu
                    .filter((menuItem) => menuItem.menu_type !== "dashboard")
                    .filter(
                      (menuItem) =>
                        !EXCLUDED_MENU_TITLES.includes(menuItem.title) &&
                        menuItem.title !== "Release Notes"
                    ) // Exclude titles including Release Notes
                    .map((menuItem) => renderNonDashboardMenuCards(menuItem))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            type="button"
            className="btnEU btn-warning"
            onClick={cancelUser}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`btnEU btn-success ${
              !usernameAvailable ? "disabled" : ""
            }`}
            disabled={
              !isFirstNameValid ||
              !isLastNameValid ||
              !isUsernameValid ||
              !usernameAvailable
            }
          >
            {user.new ? "Save" : "Update"}
          </button>
          {!user.new && (
            <button
              type="button"
              className="btnEU btn-danger"
              onClick={confirmDelete}
            >
              Delete
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserForm;
