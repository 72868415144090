import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Tooltip,
  OverlayTrigger,
  Button,
  Spinner,
  Table,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faFilter,
  faUserPlus,
  faTrash,
  faTimes,
  faFileExport,
  faSearch,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import axios from "../../api/axios";
import ChangePassword from "../ChangePassword/ChangePassword";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { UserManagementContext } from "../../context/UserManagementProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import "./UserManagement.css";

const UserManagement = () => {
  const { selectUser, cleanUpTitle, categoryStatusByUser, deleteUser } =
    useContext(UserManagementContext);
  const { selectedDistrict } = useDistrict();
  const {
    userRole,
    menu,
    userAd,
    crosscheckAdmin,
    submitter,
    itcSifAdministrator,
  } = useContext(SyncReportsContext);
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAccessColumns, setShowAccessColumns] = useState(true);
  const [loading, setLoading] = useState(true); // Add loading state

  // Fetch users with loading state
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.get(`/cc/users/${selectedDistrict.irn}`);
        const sortedUsers = response.data.users.sort((a, b) =>
          a.last_name.localeCompare(b.last_name)
        );
        setUsers(sortedUsers);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchUsers();
  }, [selectedDistrict.irn]);

  const handleClose = useCallback(() => setShowChangePasswordModal(false), []);
  const handleEditUser = useCallback(
    (user) => {
      selectUser(user);
      navigate("/edituser");
    },
    [navigate, selectUser]
  );

  const handleDeleteUser = useCallback(
    async (user) => {
      const confirmed = window.confirm(
        "Are you sure you want to delete this user? This action will remove the user from the system but will not affect their ITC Active Directory account. Do you wish to proceed?"
      );

      if (confirmed) {
        try {
          // No need to select user first; directly delete
          await deleteUser(user);

          // After successful deletion, refresh the user list
          const response = await axios.get(`/cc/users/${selectedDistrict.irn}`);
          const sortedUsers = response.data.users.sort((a, b) =>
            a.last_name.localeCompare(b.last_name)
          );
          setUsers(sortedUsers);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [deleteUser, selectedDistrict.irn]
  );

  const getReportAccessIcon = useCallback(
    (user, category) => {
      const accessLevel = categoryStatusByUser(category, user);
      const iconProps = {
        full: { icon: faCheck, color: "green" },
        partial: { icon: faFilter, color: "#33A1FD" },
        none: { icon: faBan, color: "red" },
      }[accessLevel || "none"];
      return (
        <FontAwesomeIcon
          icon={iconProps.icon}
          style={{ color: iconProps.color }}
        />
      );
    },
    [categoryStatusByUser]
  );

  const canShowAddUserButton = useMemo(() => {
    return userAd && (itcSifAdministrator || crosscheckAdmin || submitter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAd, userRole, itcSifAdministrator, crosscheckAdmin, submitter]);

  const formatDate = (date) => new Date(date).toLocaleDateString();
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const getTimeStamp = () => new Date().toLocaleString().replace(/[^\w]/g, "_");

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [users, searchTerm]
  );

  const handleClearSearch = () => {
    setSearchTerm("");
    document.getElementById("search-input").value = "";
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" variant="primary" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="card-um">
      <div className="card-header">
        {!userRole && (
          <div className="alert alert-danger" role="alert">
            <strong>Warning!</strong> Missing District Role. Please contact META
            support.
          </div>
        )}
        <h5>
          <span className="authText">
            {userAd ? "Authorized Users" : "My Account"}
          </span>
        </h5>
        <div className="userManagement-btns">
          {canShowAddUserButton && (
            <>
              <a
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                  `First Name,Last Name,Username,AD Account,Last Login\n${users
                    .map(
                      (user) =>
                        `${user.first_name},${user.last_name},${
                          user.username
                        },${user.ad_account},${
                          user.last_login
                            ? formatDate(user.last_login)
                            : "Never"
                        }\n`
                    )
                    .join("")}`
                )}`}
                download={`${
                  selectedDistrict.name
                }_Users-List_${getTimeStamp()}.csv`}
              >
                <Button
                  type="button"
                  className="btn btn-info"
                  id="addUserBtn"
                  style={{ color: "white", backgroundColor: "#FFA500" }}
                >
                  <FontAwesomeIcon
                    icon={faFileExport}
                    title="Export Users List"
                  />{" "}
                  Export Users
                </Button>
              </a>
              <Button
                type="button"
                className="btn btn-info"
                id="addUserBtn"
                style={{ color: "white" }}
                onClick={() => navigate("/edituser")}
                title="Add New User"
              >
                <FontAwesomeIcon icon={faUserPlus} /> Add New User
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="card-body-um">
        {!userAd && (
          <Button onClick={() => setShowChangePasswordModal(true)}>
            Change Password
          </Button>
        )}
        <ChangePassword
          show={showChangePasswordModal}
          handleCloseOnly={handleClose}
        />

        {userAd && (
          <>
            <InputGroup className="mb-3 search-bar-user">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <FormControl
                placeholder={`Search ${selectedDistrict.name} Users`}
                aria-label="Search users"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-bar-user"
              />
              {searchTerm && (
                <InputGroup.Text
                  id="clear-search-addon"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </InputGroup.Text>
              )}
            </InputGroup>
            <Button
              onClick={() => setShowAccessColumns((prev) => !prev)}
              variant="link"
              size="lg"
              id="accessToggle"
              title={
                showAccessColumns
                  ? "Hide Access Columns"
                  : "Show Access Columns"
              }
            >
              <FontAwesomeIcon
                icon={showAccessColumns ? faToggleOn : faToggleOff}
              />
            </Button>
            <span style={{ marginLeft: "8px" }}>
              {showAccessColumns
                ? "Hide Access Columns"
                : "Show Access Columns"}
            </span>
            <div className="table-responsive-user-management">
              <Table
                striped
                bordered
                hover
                className="bg-white text-center"
                id="userTable"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    {showAccessColumns &&
                      menu
                        .filter(
                          (category) =>
                            category.menu_type === "reports" ||
                            category.menu_type === "dashboard"
                        )
                        .map((category, index) => (
                          <th key={index}>{cleanUpTitle(category.title)}</th>
                        ))}

                    <th>Last Login</th>
                    {canShowAddUserButton && <th>Remove AD User</th>}
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <tr key={user.user_id}>
                      <td>
                        <OverlayTrigger
                          key={`tooltip-${user.user_id}`}
                          placement="top"
                          overlay={
                            <Tooltip
                              id={`tooltip-username-${user.user_id}`}
                              style={{ fontSize: "1em", textAlign: "center" }}
                            >
                              {user.username}
                            </Tooltip>
                          }
                        >
                          {user.ad_account ? (
                            <span>
                              {user.first_name && user.last_name
                                ? `${user.last_name}, ${user.first_name}`
                                : user.username}
                            </span>
                          ) : canShowAddUserButton ? (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditUser(user);
                              }}
                            >
                              {user.first_name && user.last_name
                                ? `${user.last_name}, ${user.first_name}`
                                : user.username}
                            </a>
                          ) : (
                            <span className="non-clickable-link">
                              {user.first_name && user.last_name
                                ? `${user.last_name}, ${user.first_name}`
                                : user.username}
                            </span>
                          )}
                        </OverlayTrigger>
                      </td>
                      {showAccessColumns &&
                        menu
                          .filter(
                            (category) =>
                              category.menu_type === "reports" ||
                              category.menu_type === "dashboard"
                          )
                          .map((category, index) => (
                            <td key={index}>
                              {getReportAccessIcon(user, category)}
                            </td>
                          ))}

                      <td>
                        {user.last_login
                          ? new Date(user.last_login).toLocaleDateString()
                          : "Never"}
                      </td>
                      <td>
                        {user.ad_account ? (
                          <button
                            type="button"
                            className="btn btn-danger"
                            title="Remove AD User"
                            onClick={() => handleDeleteUser(user)}
                          >
                            {user.user_id === selectUser.user_id ? (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <FontAwesomeIcon icon={faTrash} />
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            title="Cannot remove Local User"
                            className="btn btn-primary"
                            disabled
                          >
                            <FontAwesomeIcon icon={faBan} />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="card-body">
              <h5 className="authText">Legend</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
                  User has access to all of the reports in the category
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon
                    icon={faFilter}
                    style={{ color: "#33A1FD" }}
                  />{" "}
                  User has access to some of the reports in the category
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faBan} style={{ color: "red" }} /> User
                  has no access to any of the reports in the category
                </li>
                <li className="list-group-item">
                  User Name: is a Data Collector User & cannot be edited
                </li>
                <li className="list-group-item">
                  {/* eslint-disable-next-line */}
                  <a href="#">User Name</a>: is a Locally Created User
                </li>
              </ul>
            </div>
          </>
        )}
        {!userAd && (
          <div className="card-body">
            <div className="alert alert-info" role="alert">
              <strong>Info!</strong> You are not an Active Directory user. You
              can only change your password.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;

// // REFACTORED CODE 10.22.24
// import React, {
//   useCallback,
//   useContext,
//   useEffect,
//   useMemo,
//   useState,
// } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Button,
//   Col,
//   Row,
//   Spinner,
//   Table,
//   FormControl,
//   InputGroup,
//   OverlayTrigger,
//   Tooltip,
// } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFileExport,
//   faTimes,
//   faToggleOff,
//   faToggleOn,
//   faTrash,
//   faUserPlus,
//   faSearch,
//   faCheck,
//   faBan,
//   faFilter,
//   faPrint,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "../../api/axios";
// import { SyncReportsContext } from "../../context/SyncReportsProvider";
// import { UserManagementContext } from "../../context/UserManagementProvider";
// import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
// import ChangePassword from "../ChangePassword/ChangePassword";
// import "./UserManagement.css";

// const UserManagement = () => {
//   const { selectUser, cleanUpTitle, categoryStatusByUser, deleteUser } =
//     useContext(UserManagementContext);
//   const { selectedDistrict } = useDistrict();
//   const { menu, userAd } = useContext(SyncReportsContext);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showAccessColumns, setShowAccessColumns] = useState(true);
//   const [users, setUsers] = useState([]);
//   const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

//   // Fetch users with loading state
//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(`/cc/users/${selectedDistrict.irn}`);
//         const sortedUsers = response.data.users.sort((a, b) =>
//           a.last_name.localeCompare(b.last_name)
//         );
//         setUsers(sortedUsers);
//       } catch (err) {
//         console.error(err);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchUsers();
//   }, [selectedDistrict.irn]);

//   const handleEditUser = useCallback(
//     (user) => {
//       selectUser(user);
//       navigate("/edituser");
//     },
//     [navigate, selectUser]
//   );

//   const handleClose = useCallback(() => setShowChangePasswordModal(false), []);

//   const formatDate = (date) => new Date(date).toLocaleDateString();
//   const handleSearchChange = (e) => setSearchTerm(e.target.value);
//   const getTimeStamp = () => new Date().toLocaleString().replace(/[^\w]/g, "_");

//   const filteredUsers = useMemo(
//     () =>
//       users.filter(
//         (user) =>
//           user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           user.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           user.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           user.email?.toLowerCase().includes(searchTerm.toLowerCase())
//       ),
//     [users, searchTerm]
//   );

//   const handleClearSearch = () => {
//     setSearchTerm("");
//     document.getElementById("search-input").value = "";
//   };

//   const exportUsersToCSV = () => {
//     const headers = [
//       "First Name",
//       "Last Name",
//       "Username",
//       "Last Login",
//       "Menu Item",
//       "Report Title",
//       "Access",
//     ];

//     const csvContent = users
//       .filter((user) => !user.ad_account)
//       .flatMap((user) => {
//         const userDetails = [
//           user.first_name,
//           user.last_name,
//           user.username,
//           user.last_login ? formatDate(user.last_login) : "Never",
//         ];

//         return menu
//           .filter(
//             (category) =>
//               category.menu_type === "reports" ||
//               category.menu_type === "dashboard"
//           )
//           .flatMap((category) => {
//             const categoryReports = category.reports || [];
//             return categoryReports.map((report) => {
//               const hasAccess = user.reports.some(
//                 (userReport) =>
//                   userReport.report_id === report.report_id && userReport.access
//               );
//               const reportTitle = report.title.trim() || "Unknown Report";
//               return [
//                 ...userDetails,
//                 cleanUpTitle(category.title).trim(),
//                 reportTitle,
//                 hasAccess ? "true" : "false",
//               ].join(",");
//             });
//           });
//       });

//     const csvString = `${headers.join(",")}
// ${csvContent.join("\n")}`;
//     const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = `${selectedDistrict.name}_Users-List_${getTimeStamp()}.csv`;
//     link.style.display = "none";
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const printUsers = () => {
//     const headers = [
//       "First Name",
//       "Last Name",
//       "Username",
//       "Last Login",
//       "Menu Item",
//       "Report Title",
//       "Access",
//     ];

//     let htmlContent = `
//       <html>
//         <head>
//           <style>
//             table {
//               width: 100%;
//               border-collapse: collapse;
//             }
//             table, th, td {
//               border: 1px solid black;
//             }
//             th, td {
//               padding: 10px;
//               text-align: left;
//             }
//             .highlight {
//               background-color: #d4edda;
//             }
//           </style>
//         </head>
//         <body>
//           <button onclick="window.print()" style="margin-bottom: 20px;">Print Users List</button>
//           <table>
//             <thead>
//               <tr>${headers.map((header) => `<th>${header}</th>`).join("")}</tr>
//             </thead>
//             <tbody>`;

//     users
//       .filter((user) => !user.ad_account)
//       .forEach((user) => {
//         const userDetails = [
//           user.first_name,
//           user.last_name,
//           user.username,
//           user.last_login ? formatDate(user.last_login) : "Never",
//         ];

//         menu
//           .filter(
//             (category) =>
//               category.menu_type === "reports" ||
//               category.menu_type === "dashboard"
//           )
//           .forEach((category) => {
//             const categoryReports = category.reports || [];
//             categoryReports.forEach((report) => {
//               const hasAccess = user.reports.some(
//                 (userReport) =>
//                   userReport.report_id === report.report_id && userReport.access
//               );
//               const reportTitle = report.title.trim() || "Unknown Report";

//               htmlContent += `
//               <tr class="${hasAccess ? "highlight" : ""}">
//                 ${[
//                   ...userDetails,
//                   cleanUpTitle(category.title).trim(),
//                   reportTitle,
//                   hasAccess ? "true" : "false",
//                 ]
//                   .map((value) => `<td>${value}</td>`)
//                   .join("")}
//               </tr>`;
//             });
//           });
//       });

//     htmlContent += `
//             </tbody>
//           </table>
//         </body>
//       </html>`;

//     const newWindow = window.open();
//     newWindow.document.write(htmlContent);
//     newWindow.document.close();
//   };

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         <Spinner animation="border" role="status" variant="primary" size="lg">
//           <span className="visually-hidden">Loading...</span>
//         </Spinner>
//       </div>
//     );
//   }

//   return (
//     <div className="card-um">
//       <Row>
//         <Col>
//           <div className="card-header">
//             <h5>
//               <span className="authText">
//                 {userAd ? "Authorized Users" : "My Account"}
//               </span>
//             </h5>
//             <div className="userManagement-btns">
//               {userAd && (
//                 <>
//                   <Button
//                     type="button"
//                     className="btn btn-info export-users-btn"
//                     style={{
//                       color: "white",
//                       backgroundColor: "#FFA500",
//                       marginRight: "10px",
//                     }}
//                     onClick={exportUsersToCSV}
//                   >
//                     <FontAwesomeIcon
//                       icon={faFileExport}
//                       title="Download Users List"
//                     />{" "}
//                     Download Users
//                   </Button>
//                   <Button
//                     type="button"
//                     className="btn btn-info print-users-btn"
//                     style={{
//                       color: "white",
//                       marginRight: "10px",
//                       backgroundColor: "#007bff",
//                     }}
//                     onClick={printUsers}
//                     title="Print Users List"
//                   >
//                     <FontAwesomeIcon icon={faPrint} title="Print Users List" />{" "}
//                     Print Users
//                   </Button>
//                   <Button
//                     type="button"
//                     className="btn btn-info add-user-btn"
//                     style={{ color: "white", marginRight: "10px" }}
//                     onClick={() => navigate("/edituser")}
//                     title="Add New User"
//                     id="addUserBtn"
//                   >
//                     <FontAwesomeIcon icon={faUserPlus} /> Add New User
//                   </Button>
//                 </>
//               )}
//             </div>
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col>
//           <div className="card-body-um">
//             {!userAd && (
//               <Button onClick={() => setShowChangePasswordModal(true)}>
//                 Change Password
//               </Button>
//             )}
//             <ChangePassword
//               show={showChangePasswordModal}
//               handleClose={handleClose}
//             />

//             {userAd && (
//               <>
//                 <InputGroup className="mb-3 search-bar-user">
//                   <InputGroup.Text>
//                     <FontAwesomeIcon icon={faSearch} />
//                   </InputGroup.Text>
//                   <FormControl
//                     id="search-input"
//                     placeholder={`Search ${selectedDistrict.name} Users`}
//                     aria-label="Search users"
//                     value={searchTerm}
//                     onChange={handleSearchChange}
//                     className="search-bar-user"
//                   />
//                   {searchTerm && (
//                     <InputGroup.Text
//                       id="clear-search-addon"
//                       onClick={handleClearSearch}
//                       style={{ cursor: "pointer" }}
//                     >
//                       <FontAwesomeIcon icon={faTimes} />
//                     </InputGroup.Text>
//                   )}
//                 </InputGroup>

//                 <Button
//                   onClick={() => setShowAccessColumns((prev) => !prev)}
//                   variant="link"
//                   size="lg"
//                   id="accessToggle"
//                   title={
//                     showAccessColumns
//                       ? "Hide Access Columns"
//                       : "Show Access Columns"
//                   }
//                 >
//                   <FontAwesomeIcon
//                     icon={showAccessColumns ? faToggleOn : faToggleOff}
//                   />
//                 </Button>
//                 <span style={{ marginLeft: "8px" }}>
//                   {showAccessColumns
//                     ? "Hide Access Columns"
//                     : "Show Access Columns"}
//                 </span>
//                 <div className="table-responsive-user-management">
//                   <Table
//                     striped
//                     bordered
//                     hover
//                     className="bg-white text-center"
//                     id="userTable"
//                   >
//                     <thead>
//                       <tr>
//                         <th>Name</th>
//                         {showAccessColumns &&
//                           menu
//                             .filter(
//                               (category) =>
//                                 category.menu_type === "reports" ||
//                                 category.menu_type === "dashboard"
//                             )
//                             .map((category, index) => (
//                               <th key={index}>
//                                 {cleanUpTitle(category.title)}
//                               </th>
//                             ))}
//                         <th>Last Login</th>
//                         {userAd && <th>Remove User</th>}
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {filteredUsers.map((user) => (
//                         <tr key={user.user_id}>
//                           <td>
//                             <OverlayTrigger
//                               key={`tooltip-${user.user_id}`}
//                               placement="top"
//                               overlay={
//                                 <Tooltip
//                                   id={`tooltip-username-${user.user_id}`}
//                                   style={{
//                                     fontSize: "1em",
//                                     textAlign: "center",
//                                   }}
//                                 >
//                                   {user.username}
//                                 </Tooltip>
//                               }
//                             >
//                               {user.ad_account ? (
//                                 <span>
//                                   {user.first_name && user.last_name
//                                     ? `${user.last_name}, ${user.first_name}`
//                                     : user.username}
//                                 </span>
//                               ) : (
//                                 <button
//                                   className="btn btn-link p-0"
//                                   onClick={() => handleEditUser(user)}
//                                 >
//                                   {user.first_name && user.last_name
//                                     ? `${user.last_name}, ${user.first_name}`
//                                     : user.username}
//                                 </button>
//                               )}
//                             </OverlayTrigger>
//                           </td>
//                           {showAccessColumns &&
//                             menu
//                               .filter(
//                                 (category) =>
//                                   category.menu_type === "reports" ||
//                                   category.menu_type === "dashboard"
//                               )
//                               .map((category, index) => {
//                                 const accessLevel = categoryStatusByUser(
//                                   category,
//                                   user
//                                 );
//                                 const iconProps = {
//                                   full: { icon: faCheck, color: "green" },
//                                   partial: { icon: faFilter, color: "#33A1FD" },
//                                   none: { icon: faBan, color: "red" },
//                                 }[accessLevel || "none"];
//                                 return (
//                                   <td key={index}>
//                                     <FontAwesomeIcon
//                                       icon={iconProps.icon}
//                                       style={{ color: iconProps.color }}
//                                     />
//                                   </td>
//                                 );
//                               })}
//                           <td>
//                             {user.last_login
//                               ? formatDate(user.last_login)
//                               : "Never"}
//                           </td>
//                           {userAd && (
//                             <td>
//                               {user.ad_account ? (
//                                 <Button
//                                   type="button"
//                                   className="btn btn-danger"
//                                   title="Remove User"
//                                   onClick={() => deleteUser(user)}
//                                 >
//                                   <FontAwesomeIcon icon={faTrash} />
//                                 </Button>
//                               ) : (
//                                 <Button
//                                   type="button"
//                                   className="btn btn-secondary"
//                                   title="Cannot remove local user"
//                                   disabled
//                                 >
//                                   <FontAwesomeIcon icon={faBan} />
//                                 </Button>
//                               )}
//                             </td>
//                           )}
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 </div>
//                 <div className="card-body">
//                   <h5 className="authText">Legend</h5>
//                   <ul className="list-group list-group-flush">
//                     <li className="list-group-item">
//                       <FontAwesomeIcon
//                         icon={faCheck}
//                         style={{ color: "green" }}
//                       />{" "}
//                       User has access to all of the reports in the category
//                     </li>
//                     <li className="list-group-item">
//                       <FontAwesomeIcon
//                         icon={faFilter}
//                         style={{ color: "#33A1FD" }}
//                       />{" "}
//                       User has access to some of the reports in the category
//                     </li>
//                     <li className="list-group-item">
//                       <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />{" "}
//                       User has no access to any of the reports in the category
//                     </li>
//                     <li className="list-group-item">
//                       User Name: is a Data Collector User & cannot be edited
//                     </li>
//                     <li className="list-group-item">
//                       <a href="#">User Name</a>: is a Locally Created User []
//                     </li>
//                   </ul>
//                 </div>
//               </>
//             )}
//             {!userAd && (
//               <div className="card-body">
//                 <div className="alert alert-info" role="alert">
//                   <strong>Info!</strong> You are not an Active Directory user.
//                   You can only change your password.
//                 </div>
//               </div>
//             )}
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default UserManagement;
