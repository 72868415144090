import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import axios from "../api/axios";
import { useDistrict } from "../components/Breadcrumbs/Breadcrumbs";
import { SyncReportsContext } from "./SyncReportsProvider";
import { useNavigate } from "react-router-dom";

export const UserManagementContext = createContext();

export const UserManagementProvider = ({ children }) => {
  const { selectedDistrict } = useDistrict();
  const { menu, userRole } = useContext(SyncReportsContext);

  const navigate = useNavigate();

  const firstNameInputRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tmpTimeout, setTmpTimeout] = useState(null);
  const [userError, setUserError] = useState(null);
  const [userFormValid, setUserFormValid] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [users, setUsers] = useState([]);

  // Debounce function to limit API calls when checking username availability
  const debounce = (func, delay) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const processReportList = (user, reportList) => {
    user.reportList = JSON.parse(JSON.stringify(reportList));
    user.reportList.forEach((category) => {
      category.reports.forEach((report) => {
        const foundReport = user.reports.find(
          (element) => element.report_id === report.report_id
        );
        if (foundReport) {
          report.selected = foundReport.access;
          if (report.child_report) {
            // Logic to handle child reports
            const foundChild = category.reports.find(
              (element) => element.report_id === report.child_report.report_id
            );
            if (foundChild) {
              report.child_report = foundChild;
            }
          }
        }
      });
    });
  };

  const checkUsername = async (username) => {
    if (username) {
      try {
        const response = await axios.post(`/cc/check_username`, { username });
        if (response.data.status === 1) {
          setUsernameAvailable(true);
        } else if (
          selectedUser &&
          selectedUser.user_id === response.data.user_id
        ) {
          setUsernameAvailable(true);
        } else {
          setUsernameAvailable(false);
        }
      } catch (error) {
        console.error("Error in checkUsername:", error);
        setUsernameAvailable(false);
      }
    } else {
      setUsernameAvailable(false);
    }
  };

  // debounce on each keystroke
  const debouncedCheckUsername = debounce(checkUsername, 500);

  // Select a user to edit
  const selectUser = (user) => {
    const selectedUserCopy = JSON.parse(JSON.stringify(user));
    processReportList(selectedUserCopy, menu); // Process report list for the selected user

    setUsernameAvailable(true);
    setSelectedUser(selectedUserCopy);

    selectedUserCopy.reportList.forEach((category) => {
      category.reports.forEach((report) => {
        const foundReport = selectedUserCopy.reports.find(
          (element) => element.report_id === report.report_id
        );
        if (foundReport) {
          report.selected = foundReport.access;
          if (report.child_report) {
            const foundChild = category.reports.find(
              (element) => element.report_id === report.child_report.report_id
            );
            if (foundChild) {
              report.child_report = foundChild;
            }
          }
        }
      });
    });

    setSelectedUser(selectedUserCopy);
  };

  const categoryStatusByUser = (category, user) => {
    // Check if the user is an AD user. If so, return 'full' immediately.
    if (user.ad_account) {
      return "full";
    }

    // Proceed with existing logic if not an AD user.
    if (!user.reports) return "none";
    let userReports = 0;
    category.reports.forEach((catReport) => {
      user.reports.forEach((userReport) => {
        if (catReport.report_id === userReport.report_id && userReport.access) {
          userReports++;
        }
      });
    });

    if (userReports === 0) {
      return "none";
    } else if (userReports === category.reports.length) {
      return "full";
    } else {
      return "partial";
    }
  };

  // Toggle all reports in a category on or off (depending on current state)
  const toggleAll = (menuToUpdate) => {
    const totalReports = menuToUpdate.reports.length;
    const selectedReports = menuToUpdate.reports.filter(
      (report) => report.selected
    ).length;

    const allSelected = selectedReports === totalReports;
    menuToUpdate.reports.forEach((report) => {
      report.selected = !allSelected;
      if (report.child_report) {
        report.child_report.selected = !allSelected;
      }
    });

    // Update the state or context as necessary
    setUsers(users);
  };

  // Count the number of selected reports in a category
  const totalSelected = (menuToCount) => {
    return menuToCount.reports.filter((report) => report.selected).length;
  };

  // Function to clean up report titles in user management
  const cleanUpTitle = (title) => {
    return title
      .replace(" Checks", "")
      .replace("/", " / ")
      .replace("Graduation", "Grad");
  };

  // Add tooltips to report titles
  const addReportTooltips = () => {
    clearTimeout(tmpTimeout);
    const timeoutId = setTimeout(() => {
      const reportTooltips = document.querySelectorAll(".report-tooltip");
      reportTooltips.forEach((tooltip) => {
        tooltip.setAttribute("data-tooltip", tooltip.innerText);
      });
    }, 1000);
    setTmpTimeout(timeoutId);
  };

  // Get report info
  const getInfo = (report) => {
    return report.more_info;
  };

  const addUser = () => {
    if (!userRole) {
      console.error("Insufficient permissions to add a user.");
      return;
    }

    // Initialize a new user object
    const newUser = {
      new: true, // Flag indicating this is a new user
      reportList: JSON.parse(JSON.stringify(menu)), // Clone the menu for the reportList
      ad_account: false,
    };

    // Set the new user as the selectedUser for editing
    setSelectedUser(newUser);

    setUsernameAvailable(true);

    // Initialize tooltips if used in the application
    addReportTooltips();
  };

  const updateUser = async (user) => {
    if (!user) return;

    // Clone the user object to avoid direct mutations
    let updatedUser = JSON.parse(JSON.stringify(user));

    // If a new password is provided, include it in the update. Otherwise, exclude the password field.
    const updatePayload = {
      ...updatedUser,
      newpassword: undefined, // Ensure newpassword is not sent to the backend
      password: undefined, // Exclude password from payload unless it's being explicitly updated
    };

    // Only add the password to the payload if a new password is explicitly provided
    if (updatedUser.newpassword) {
      updatePayload.password = updatedUser.newpassword;
    }

    // Remove properties not needed for the backend or that should not be updated accidentally
    delete updatePayload.new;
    delete updatePayload.last_login;
    delete updatePayload.$$hashKey;
    delete updatePayload.reportList; // Assuming report handling is managed separately
    delete updatePayload.active;
    delete updatePayload.ad_account;
    delete updatePayload.district_id;

    try {
      const response = await axios.post(
        `/cc/users/${selectedDistrict.irn}`,
        updatePayload
      );
      if (response.data && response.data.users) {
        setUsers(response.data.users); // Update your users state with the response
      }
      cancelUser(); // Reset the form/UI state if necessary
    } catch (error) {
      console.error("Error in updateUser:", error);
      // Handle error state as needed
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`/cc/users/${selectedDistrict.irn}`);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const deleteUser = async (user) => {
    if (!user) return;

    try {
      await axios.delete(`/cc/users/${selectedDistrict.irn}`, {
        data: { user_id: user.user_id },
      });
      await fetchUsers(); // Refresh the users list
      cancelUser(); // Reset form state
    } catch (error) {
      console.error("Error in deleteUser:", error);
    }
  };

  const deleteLocalUser = async () => {
    if (!selectedUser) return;

    try {
      await axios.delete(`/cc/users/${selectedDistrict.irn}`, {
        data: { user_id: selectedUser.user_id },
      });
      await fetchUsers(); // Refresh the users list
      cancelUser(); // Reset form state
    } catch (error) {
      console.error("Error in deleteUser:", error);
    }
  };

  // Cancel user edit or add
  const cancelUser = () => {
    setSelectedUser(null);
    setUsernameAvailable(true);
    setUserError(null);
    setUserFormValid(false);

    navigate("/usermanagement");
  };

  // Set users menu and reset user state
  const setUsersMenu = (selectedMenu) => {
    setUsers(selectedMenu);
    cancelUser();
  };

  //  Reset a user's password
  const resetPassword = async () => {
    if (!selectedUser) return;

    const updatedUser = { ...selectedUser };

    if (updatedUser.newpassword) {
      updatedUser.password = updatedUser.newpassword;
    }

    if (!updatedUser.password) {
      cancelUser();
      return true;
    }

    try {
      const response = await axios.post(
        `/cc/users/${selectedDistrict.irn}/${updatedUser.user_id}`,
        {
          username: updatedUser.username,
          user_id: users[0].user_id, // Make sure this logic is correct
          password: updatedUser.password,
        }
      );
      setUsers(response.data);
      cancelUser();
    } catch (error) {
      console.error("Error in resetPassword:", error);
      setSelectedUser({
        ...updatedUser,
        errormessage: error.response?.data || "An error occurred",
      });
    }
  };

  // Get initial data
  useEffect(() => {}, []); // Empty dependency array ensures this runs only once on mount

  return (
    <UserManagementContext.Provider
      value={{
        ad: users.ad,
        addUser,
        addReportTooltips,
        cancelUser,
        categoryStatusByUser,
        checkUsername: debouncedCheckUsername,
        cleanUpTitle,
        deleteUser,
        firstNameInputRef,
        getInfo,
        processReportList,
        resetPassword,
        selectUser,
        selectedUser,
        setSelectedUser,
        setUserError,
        setUserFormValid,
        setUsers,
        setUsersMenu,
        toggleAll,
        totalSelected,
        updateUser,
        userError,
        userFormValid,
        usernameAvailable,
        users,
        deleteLocalUser,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
