import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Spinner } from "react-bootstrap";

function Extract() {
  const [buildExport, setBuildExport] = useState(false);
  const [, setExportComplete] = useState(false);
  const [, setExportError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [isExportComplete, setIsExportComplete] = useState(false);

  const { reportId } = useParams();
  const { reports, selectedYear, runReport, findReportById } =
    useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();

  useEffect(() => {
    const fetchedReport = reports[reportId];
    if (fetchedReport && fetchedReport.report) {
      // console.log("Report title:", fetchedReport.report.title); // Debugging
      setTitle(fetchedReport.report.menu_title || "");
    }
  }, [reportId, reports]);

  const resetExportState = () => {
    setBuildExport(false);
    setExportComplete(false);
    setExportError(null);
    setIsExportComplete(false);
  };

  const runExport = async () => {
    resetExportState();
    setBuildExport(true);

    try {
      const reportToUpdate = findReportById(reportId);
      if (reportToUpdate) {
        await runReport(reportToUpdate);
      }

      const updatedReport = reports[reportId];
      if (!updatedReport || !updatedReport.data) {
        throw new Error("Failed to get updated report data");
      }

      const response = await axios.post(
        `/cc/export/${selectedDistrict.irn}/report/${reportId}/year/${selectedYear}`,
        {
          data: updatedReport.data,
        }
      );

      // Build the CSV content
      let rtn = response.data.headers + "\r\n";
      response.data.data.forEach((row) => {
        rtn += row.row + "\r\n";
      });

      // Updated date format with leading zeros for month and day
      const today = new Date();
      const dateTime = `${today.getFullYear()}-${String(
        today.getMonth() + 1
      ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

      // Use appropriate MIME type for Excel (CSV)
      const txtFile = new Blob([rtn], { type: "application/vnd.ms-excel" });

      // Ensure correct file name is used
      const fileName = `${title}_${dateTime}.csv`;

      // Create the download link and initiate download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(txtFile);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href); // Free up the URL object after download

      setIsExportComplete(true); // Mark the export as complete
    } catch (error) {
      console.error(
        `There was an error while creating the Export: ${
          error.message || error
        }`
      );
      setExportError(error);
    } finally {
      setBuildExport(false);
    }
  };

  const toggleModal = () => {
    if (!showModal) {
      resetExportState();
    }
    setShowModal(!showModal);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={toggleModal}
        className="extractBtn"
        title="Download Report"
      >
        <FontAwesomeIcon icon={faFileExport} />
      </Button>
      <Modal show={showModal} onHide={toggleModal} className="exportModal">
        <Modal.Header closeButton>
          <Modal.Title>Export Builder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {buildExport ? (
            <div>
              <Spinner animation="border" variant="primary" /> Exporting...
            </div>
          ) : isExportComplete ? (
            <div>Export Complete!</div>
          ) : (
            <Button variant="primary" onClick={runExport}>
              Click to Export
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Extract;
